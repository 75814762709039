import { AxiosResponse } from "axios";
import { QuoteAdminPartStatuses } from "@/enums/QuoteAdminPartStatuses";
import { UserTypes } from "@/enums/UserTypes";
import ApiService from "@/services";
import { CostingInterface } from "@/types/api/CostingInterface";
import { QuoteFiltersInterface } from "@/types/api/QuoteFiltersInterface";
import RfqListPart from "@/types/request-for-quote/RfqListPart";
import { ShippingChangeInterface } from "@/types/api/ShippingChangeInterface";
import QuoteEngineerAdjustmentInterface from "@/types/summary/QuoteEngineerAdjustmentInterface";
import { RfqPartParamsInterface } from "@/types/api/RfqPartParamsInterface";
import { PartCostingRawMaterialPatchPayload } from "@/types/costing/PartCostingRawMaterialPatchPayload";

export default class QuoteService {
  static createRequestForQuote(
    names: string[],
    contactUser: { customerId: string; userId: string } | undefined
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", { names, contactUser });
  }

  static checkForRejectedFiles(payload: {
    rfqId: string;
    names: string[];
    contactUser: { customerId: string; userId: string };
    createParts: boolean;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", payload);
  }

  static updateRequestForQuote(
    id: string,
    names: string[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", { rfqId: id, names });
  }

  static submitRequestForQuote(
    id: string,
    status: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { status });
  }

  static requestExportOfQuoteFiles(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/export`, {
      params: { rfqId },
      baseURL: process.env.VUE_APP_UPLOAD_API_URL,
    });
  }

  static changeQuoteCurrency(
    id: string,
    currencyId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { currencyId });
  }

  static getQuoteList(params: QuoteFiltersInterface): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq", { params });
  }

  static downloadQuoteReport(
    params: QuoteFiltersInterface
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/report`, { params, responseType: "blob" });
  }

  static deleteFileFromRfq(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/file/${id}`);
  }

  static addRfqList(payload: {
    rfqId: string;
    parts: RfqListPart[];
    contactUser: { customerId: string; userId: string } | undefined;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq/list", payload);
  }

  static createRfqPartByFile(payload: {
    rfqId: string;
    parts: {
      customerPNRev: string;
      modelId: string;
      drawingId?: string;
      quantities?: number[];
      remarks?: string[];
    }[];
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-part", payload);
  }

  static updatePart(
    id: string,
    payload: {
      partId: string;
      status: QuoteAdminPartStatuses;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, payload);
  }

  static updateRfqPartModel(
    id: string,
    modelId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, { modelId });
  }

  static updateRfqPartDrawing(
    id: string,
    drawingId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, { drawingId });
  }

  static updatePartNumber(payload: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${payload.id}`, {
      customerPNRev: payload.name,
    });
  }

  static updatePartQuantities(
    id: string,
    quantities: number[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      quantities,
    });
  }

  static updatePartRemarks(
    id: string,
    remarks: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      remarks,
    });
  }

  static updatePartStatus(id: string, status: number): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      status,
    });
  }

  static changeRfqPartReviewedStatus(
    rfqPartId: string,
    reviewedStatus: boolean
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${rfqPartId}`, {
      reviewed: reviewedStatus,
    });
  }

  static updatePartRejectionReason(
    id: string,
    rejectionReason: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      rejectionReason,
    });
  }

  static removePart(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-part/${id}`);
  }

  static getRfqParts(params: RfqPartParamsInterface): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part`, {
      params,
    });
  }

  static getPartProductionSpeedOptions(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/options/production-speed/${partId}`);
  }
  static updatePartProductionSpeed(
    partId: string,
    optionId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${partId}`, {
      productionSpeed: optionId,
    });
  }

  static getPartQualityControlOptions(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/options/quality-control/${partId}`);
  }
  static updatePartQualityControl(
    partId: string,
    optionId: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${partId}`, {
      qualityControl: optionId,
    });
  }

  static deleteRfqPart(partId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-part/${partId}`);
  }

  static updateRfqName(id: string, name: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { name });
  }

  static getQuote(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/${id}`);
  }
  static getQuoteAdditionalFiles(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/additional-files/${id}`);
  }

  static updateRfqOption(
    rfqId: string,
    optionType: {
      productionSpeed?: string;
      qualityControl?: string[];
      shipping?: string;
      replaceQualityControl?: boolean;
      partProductionLocations?: Array<Location>;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/options/${rfqId}`, optionType);
  }

  static getWorkDays(): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/work-days`);
  }

  static getPartProductionOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/part-production/${rfqId}`);
  }

  static getQualityControlOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/quality-control/${rfqId}`);
  }

  static getShippingOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/shipping/${rfqId}`);
  }
  static getShippingOptionsBySalesRegion(params: {
    salesRegionId: string;
    rfqId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/shipping`, { params });
  }
  static updateShippingOption(params: {
    optionsId: string;
    rfqId: string;
    countryId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/options/shipping`, params);
  }

  static getShippingAddress(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/shipping-address/${rfqId}`);
  }
  static changeShippingAddress(payload: {
    id: string;
    shippingAddress: ShippingChangeInterface;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v1/rfq/shipping-address/${payload.id}`,
      payload.shippingAddress
    );
  }

  static downloadExcelFile(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/excel/download/${id}`, {
      responseType: "arraybuffer",
    });
  }

  static downloadPdfFile(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/export/pdf", {
      params: { rfqId },
      responseType: "blob",
    });
  }

  static uploadCostingFile(
    id: string,
    formData: FormData
  ): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq-part/costing/upload/${id}`, formData);
  }

  static downloadCostingFile(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/costing/download`, {
      params: {
        rfqId,
        type: "excel",
      },
      responseType: "arraybuffer",
    });
  }

  static downloadQuoteFiles(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/drawings/export", {
      params: {
        rfqId,
      },
      responseType: "arraybuffer",
    });
  }

  static getCostingPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/costing/${rfqPartId}`);
  }

  static updateQuotePart(
    partId: string,
    part: CostingInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/${partId}`, part);
  }

  static verifyPart(params: {
    customerPN: string;
    customerRev?: string;
    rfqPartId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/costing/verify`, {
      params,
    });
  }
  static getFiles(params: {
    rfqPartId: string;
    partId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/costing/verification-files/`, {
      params,
    });
  }

  static getCostingSummary(
    rfqPartId: string,
    manufacturedRegion: string
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v1/rfq-part/summary/${rfqPartId}?region=${manufacturedRegion}`
    );
  }

  static getTechnicalFeedback(params: {
    rfqPartId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-feedback`, { params });
  }

  static removeTechnicalFeedback(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-feedback/message/${id}`);
  }

  static addTechnicalFeedback(feedback: {
    feedbackId: string;
    messages: string[];
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-feedback/message", {
      feedbackId: feedback.feedbackId,
      messages: feedback.messages,
    });
  }

  static addTechnicalFeedbackDrawing(
    payload: FormData
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-feedback/upload", payload);
  }

  static removeTechnicalFeedbackDrawing(payload: {
    id: string;
    drawingId: string;
  }): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v1/rfq-feedback/drawings/${payload.id}/${payload.drawingId}`
    );
  }

  static updatePartActiveQuantity(
    id: string,
    index: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      enabledQuantity: index,
    });
  }

  static changeQuoteStatus(id: string, status: number): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { status });
  }

  static getCheckoutFile(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/export/checkout/excel`, {
      params: { rfqId },
      responseType: "arraybuffer",
    });
  }

  static getPurchaseOrderTemplate(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/export/checkout/po-template`, {
      params: { rfqId },
      responseType: "arraybuffer",
    });
  }

  static setQuotePurchaseOrder(
    rfqId: string,
    purchaseOrderFileId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${rfqId}`, { purchaseOrderFileId });
  }

  static sendTechnicalFeedbackNotification(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq/${id}/send-technical-feedback-email`);
  }

  static createQuoteRevision(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq/revision/${id}`);
  }

  static deleteQuoteRevision(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/revision/${id}`);
  }

  static getQuoteEngineeringAdjustmentCalculationPreview(
    id: string,
    payload: QuoteEngineerAdjustmentInterface,
    region: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v1/rfq-part/summary/quote-engineer-adjustment/${id}`,
      {
        ...payload,
        region,
      }
    );
  }

  static saveQuoteEngineeringAdjustment(
    id: string,
    payload: QuoteEngineerAdjustmentInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v1/rfq-part/summary/quote-engineer-adjustment/${id}`,
      {
        ...payload,
      }
    );
  }

  static deleteRfq(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/${id}`);
  }

  static getRfqPartById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/${id}`);
  }
  static getPartInfo(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/${id}`);
  }
  static getPartFiles(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/documents/part-info/${id}`);
  }
  static updatePartInfo(
    partId: string,
    part: Partial<CostingInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/${partId}`, part);
  }
  static getPartInfoByPNRev(params: {
    skip: number;
    limit: number;
    customerPN?: string;
    customerRev?: string;
    number?: string;
    rev?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts`, { params });
  }

  static getPartFilters(
    params: RfqPartParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/filters`, { params });
  }

  static getPartDocuments(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/documents/${id}`);
  }
  static deletePartDocument(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/parts/documents/${id}`);
  }
  static addPartDocument(params: {
    partId: string;
    userTypes: UserTypes[];
    fileId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post(`/v1/parts/documents`, params);
  }
  static deletePartDocumentAttachment(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${id}`, { fileId: null });
  }
  static updatePartDocumentLabel(params: {
    id: string;
    label: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      label: params.label,
    });
  }
  static updatePartDocumentUserTypes(params: {
    id: string;
    userTypes: UserTypes[];
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      userTypes: params.userTypes,
    });
  }
  static updatePartDocumentFile(params: {
    id: string;
    fileId: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      fileId: params.fileId,
    });
  }

  static getPartPreviews(partIds: string[]): Promise<AxiosResponse> {
    return ApiService.get("/v1/parts/documents/part-previews", {
      params: {
        partIds,
      },
    });
  }

  static getDataForFilter(): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/for-filters");
  }

  static getPartsToReviewCount(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/count-to-review", {
      params: { rfqId },
    });
  }

  // V2
  static getPartInfoV2(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}`);
  }

  static getRfqPartInfoV2(
    rfqPartId: string,
    select?: string[]
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${rfqPartId}`, {
      params: { select },
    });
  }

  static getPartCostingVersions(partId: string): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costings/", {
      params: {
        skip: 0,
        limit: 9999,
        partId,
      },
    });
  }

  static verifyPartV2(params: {
    customerPN: string;
    customerRev?: string;
    rfqPartId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/verify`, {
      params,
    });
  }

  static getPartCosting(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}/costing`);
  }

  static getPartCostingRawMaterial(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}/costing-raw-material`);
  }

  static createPartCostingRawMaterial(
    payload: PartCostingRawMaterialPatchPayload
  ): Promise<AxiosResponse> {
    return ApiService.post(`/v2/parts/costing-raw-materials/`, payload);
  }

  static changePartCostingRawMaterial(
    partId: string,
    payload: PartCostingRawMaterialPatchPayload
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costing-raw-materials/${partId}`,
      payload
    );
  }

  static getPartRelatedQuotes(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}/related-quotes`);
  }

  static changeQuoteCostingVersion(
    rfqId: string,
    version: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${rfqId}/costing-version`, { version });
  }

  static connectPartToRfqPart(
    rfqPartId: string,
    partId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/connect-part`, {
      partId,
    });
  }
}
